import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as appTools from "../utils/tools.js";
import axios from "axios";
const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';

/*
const AppContext=React.createContext({isOnline:  window.localStorage.getItem("userOnline")!== null?true:false});*/

const AppContext = React.createContext<any>({});

export const AppContextProvider = ({ children }: any) => {

  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [checkToken, setCheckToken] = useState(false);
  const [userInfo, setUserInfo] = useState({
    id: 0,
    name: '',
    lastProfile: '',
    email: '',
    biography: '',
    money: 0,
    hp: 0,
    mp: 0,
    defaultLanguage: 'en',
    totalClassrooms: 0,
    totalContact: 0,
    totalSkills: 0,
    totalMessages: 0,
    totalAlerts: 0,
    profilePicture: '',
    coverPicture: '',
    showChat: '', 
    enableDarkMode: false,
    currentPage: 'home'
  });
  const [errorLogin, setErrorLogin] = useState(false);
  const loginUser = (userLogin: any, userPassword: any) => {
    axios.post(baseURL + 'userlogin/',
      {
        password: userPassword,
        login: userLogin
      }).then((res: any) => {
        if (res.data.status === 'error') {
          // setErrorState({
          // ...errorState,
          // error: 'error'
          // });
          setErrorLogin(true);
          // alert('error');
        } else if (res.data.status === 'success') {
          localStorage.setItem("loginToken", res.data.login.token);
          localStorage.setItem("userName", res.data.login.userName);
          localStorage.setItem("userType", res.data.login.userType);
          localStorage.setItem("userBiography", res.data.login.userBiography);
          localStorage.setItem("userMoney",  res.data.login.userMoney);
          localStorage.setItem("userId", res.data.login.userId);
          setIsLogin(true);
          setErrorLogin(false);
          setUserInfo(prevState => ({
            ...prevState,
            id: res.data.login.userId,
            name: res.data.login.userName || localStorage.getItem('userName'),
            biography: res.data.login.userBiography || localStorage.getItem('userBiography'),
            money: parseInt(res.data.login.userMoney,10) || parseInt(localStorage.getItem('userMoney')+'',10)
          }));

          // {id: 0,
          // name: res.data.login.userName, 
          // lastProfile: '', 
          // email: '',
          // bio: '',
          // hp: 0,
          // mp: 0,
          // totalClassrooms: 0, 
          // totalContact: 0,
          // totalSkills: 0,
          // totalMessages: 0,
          // totalAlerts: 0});
          // console.log('login token', res.data.login.token);
          // console.log('username', res.data.login.userName);
          // console.log('email: ', res.data.login.userEmail);


          // setErrorState({
          // ...errorState,
          // error: 'success'
          // });
          // prop.loginProp(true);
          // setTimeout(()=>{
          navigate('/profile/');
          //}, 800);
        }
      });
  }

  const logout = () => {

    localStorage.removeItem('loginToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    localStorage.removeItem('userDateCreation');
    // navigate('/login/');
    setIsLogin(false);

  }

  const checkingToken = () => {
    axios.post(baseURL + 'checktoken', {
      'loginToken': localStorage.getItem('loginToken')

    }).then(res => {
      if (res.data.status === 'success') {
        setCheckToken(true);
      }
    })
  }

  const checkUserOnline = () => {
    //appContext=useContext(AppContext);
    //console.log(appContext.isOnline);
    checkingToken();
    if (localStorage.getItem("loginToken") !== null && checkToken) {
      //checkLoginState(true);
      setIsLogin(true);
      return true;
    }
    return false;
  }

  const value = { checkToken, setCheckToken, logout, isLogin, setIsLogin, loginUser, checkingToken, errorLogin, userInfo, setUserInfo };
  return (<AppContext.Provider value={value}>{children}</AppContext.Provider>);
}
export default AppContext;
