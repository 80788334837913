import React from "react";
import { Link } from "react-router-dom";
import { FaHome, FaPhone, FaPlay, FaUser, FaLinkedin, FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaCheck, FaWhatsapp } from "react-icons/fa";
import { HiHome, HiMenu, HiPhone, HiMail, HiCheck, HiShare, HiOutlineChevronRight } from "react-icons/hi";
import { BsArrowRight as ArrowRight, BsPlusCircle as AddIcon, BsArrowUp as TopIcon } from "react-icons/bs";
import { FaFacebookF as FbIcon, FaLinkedinIn as LinkedinIcon, FaGithub } from "react-icons/fa";

import * as tools from "../utils/tools";
import FranckLogo from "../../image/footer-logo-400x400.png";
import NewsLetter from "../newsletter/NewsLetter";

export const Footer = () => {
    const iconStyle = { fill: '#fff', width: '20px', height: '20px', marginRight: '10px' }

    return <>

        {/*<div className="app-footer-service">
		    <div className="section-default-wrap">
            <div>
                <h2>{tools.translate('Vous avez un projet web?')}</h2>
            </div> 
            <div>

                <Link to="/contact" className="homepage-bt">{tools.translate('Nous contacter')}</Link>
            </div>
			</div>
        </div>*/}
        <footer className="app-footer">

            <div className="app-footer-container elem-row default-container app-flex">

                <div className="address-wrap elem-col">
                    <h2>{tools.translate('Francky Rakoto')}</h2>
                    {/* <p>{tools.translate(`A Fullstack software developer from Madagascar`)}</p> */}
                    {/* <p ><a href="#" className="flex-centered g5"><FaHome className="animate-icon"/>{tools.translate('I live in Antananarivo with my family')}</a></p> */}
                    <p ><a className="flex-centered g5" href="mailto:hello@franckyrakoto.com"><HiMail className="animate-icon" />Say hi</a></p>
                    <p ><a className="flex-centered g5" href="tel:+261326510180"><FaWhatsapp className="animate-icon" />+261 32 65 101 80</a></p>
                    <div className="social-wrap  ">
                        {/* <h2>{tools.translate('Social')}</h2> */}
                        <a href="https://web.facebook.com/franckywebdevstudio" target="_blank" className="custom-social-icon"><FbIcon className="animate-icon" /></a>
                        <a href="https://linkedin.com/in/franckycodes/" target="_blank" className="custom-social-icon"><LinkedinIcon className="animate-icon" /></a>
                        <a href="https://github.com/franckycodes" className="_blank"><FaGithub className="animate-icon" /></a>
                    </div>
                </div>

                <div className="news-letter-wrap elem-col">
                    <h2>{tools.translate('News Letter')}</h2>
                    <NewsLetter />

                </div>

                {/*<div className="about-wrap">
                   
                    
                </div>*/}
                {/*<div className="my-services-wrap">
                    <h2>Services</h2>
                    <ul><li><Link to="/contact" >Collaboration</Link></li>
                        <li><Link to="/contact" >Développement web</Link></li>
                        <li><Link to="/contact" >Cours en ligne</Link></li>
                        <li><Link to="/contact" >Web Design</Link></li>
                    </ul>
               
                    <div className="contact-us">
                        <Link to="/contact" className="homepage-bt">Contactez-nous <AddIcon className="icon" /></Link>
                    </div>
                  
                </div>
                <div className="social-wrap">
                    <h2>{tools.translate('Social')}</h2>
                    <div className="social-links app-flex"  >
                        <a href="https://www.linkedin.com/in/franckycodes/" target="_blank"><FaLinkedin /></a>
                        <a href="https://facebook.com/franckycodes" target="_blank"><FaFacebook /></a>
                        <a href="https://github.com/franckycodes" target="_blank"><FaGithub /></a>
                       
                    </div>
                </div>*/}

            </div>
            <div className="copyright-wrap">
                <div><a href="#contact-me">Francky Rakoto</a> &copy; 2024</div>
                {/* <div className="footer-logo-wrap app-flex"><img className="animate-icon" src={FranckLogo} style={{ width: "32px", height: "32px", borderRadius: "8px", background: "#000" }} />{tools.translate('React Template Made by Francky Rakoto')}</div> */}

            </div>
            <div><a href="#" className="go-top animate-icon"><TopIcon className="animate-icon" /></a></div>
        </footer>
    </>
}


export default Footer;
