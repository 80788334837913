import React, { useState, useContext } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Link
} from "react-router-dom";
import axios from "axios";
import "../../template/portfoliofrancky/css/app.css";
import { FaPhone, FaPlay, FaUser, FaLinkedin, FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaCheck, FaDownload } from "react-icons/fa";
import { HiMenu, HiPhone, HiMail, HiCheck } from "react-icons/hi";
import { BsArrowRight as ArrowRight } from "react-icons/bs";
import "../../../fonts/Lato/Lato-Bold.ttf";
import "../../../fonts/Poppins/Poppins-Bold.ttf";
import "../../../fonts/Poppins/Poppins-Regular.ttf";

import Header from "../header/Header";
import Footer from "../footer/Footer";
import parse from "html-react-parser";
import UserContext from "../context/UserContext";
import HomeBanner from "./HomeBanner";
import * as tools from "../utils/tools";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import BannerSlide from "./BannerSlide";
import { BsArrowRight as RightIcon, BsArrowLeft as LeftIcon } from "react-icons/bs";
import Pagination from "../pagination/Pagination";
import ReactIcon from "../../image/react_icon.png";
import CppIcon from "../../image/c++.png";
import WpIcon from "../../image/wordpress.png";
import PhpIcon from "../../image/php_icon.png";
import JsIcon from "../../image/js_icon.png";
import HtmlIcon from "../../image/html_icon.png";
import CssIcon from "../../image/css_icon.png";
import ContactImage from "../../image/contact.jpg";
import TeamBannerImage from "../../image/team-banner.jpg";
import TeamBannerImageMin from "../../image/banner-min.jpg";
import AboutMeImage from "../../image/about-me-image.png";
import AboutMeRounded from "../../image/about-me-rounded.png";
import AnimateTextWidget from "../widgets/animatetextwidget/AnimateTextWidget";
import AppContext from "../context/AppContext";
import { Button, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
//import PuffLoader from "react-spinners/PuffLoader";
let baseURL: any;
let appHost: any;
let appUpload: any;

baseURL = process.env.REACT_APP_API_URL;
appHost = process.env.REACT_APP_APP_HOST;
appUpload = process.env.REACT_APP_APP_UPLOAD;

export const Home = () => {

    const userData = useContext(UserContext);
    const { userInfo, setUserInfo } = useContext(AppContext);
    const [userState, setUserState] = useState({
        userName: '',
        userType: '',

    });
    const [state, setState] = useState({
        userLogin: false,
        showLeftBar: false,
        messageSent: false,
        startSendingMessage: false,
        // recentPosts:[],
        // recentProducts:[],
        // recentServices:[]
    })

    const [contactState, setContactState] = useState({
        userName: '',
        userEmail: '',
        userMessage: '',
        userMessageCompliance: ''
    })

    //products state
    const [productState, setProductState] = useState({
        recentProducts: []
    })

    //posts state
    const [postState, setPostState] = useState({
        recentPosts: []
    })

    //services state
    const [serviceState, setServiceState] = useState({
        recentServices: []
    })
    const login = () => {
        setState({
            ...state,
            userLogin: !state.userLogin
        })
    }

    const getRecentPosts = () => {

        axios.get(baseURL + 'articles/').then((response) => {
            //console.log(response.data.articles);
            setPostState({
                ...postState,
                recentPosts: response.data.articles
            })
        })
    }
    const getRecentProducts = () => {

        axios.get(baseURL + 'shop/').then((response) => {
            //console.log(response.data.articles);
            setProductState({
                ...productState,
                recentProducts: response.data.articles
            })
        })
    }
    const getRecentServices = () => {

        axios.get(baseURL + 'services/').then((response) => {
            //console.log(response.data.articles);
            setServiceState({
                ...serviceState,
                recentServices: response.data.articles
            })
        })

    }

    const iconStyle = { fill: '#4ad', width: '32px', height: '26px' }

    const toggleLeftBar = () => {
        setState({
            ...state,
            showLeftBar: !state.showLeftBar
        })
    }

    const submitContactForm = (e: any) => {

        e.preventDefault();
        setState({
            ...state,
            startSendingMessage: true
        })
        let dataToSend = {
            message: contactState.userMessage,
            email: contactState.userEmail,
            name: contactState.userName,
            acceptCompliance: contactState.userMessageCompliance
        };

        if (dataToSend.message && dataToSend.email && dataToSend.name && dataToSend.acceptCompliance) {



            axios.post(baseURL + "contactus",
                dataToSend).then(response => {
                    //console.log(response.data.status);
                    //console.log(typeof response.data.status);
                    if (response.data.status === 'success') {
                        console.log('message sent');
                        setState({
                            ...state,
                            messageSent: true
                        });
                    } else {
                        setState({
                            ...state,
                            messageSent: false
                        });
                    }
                })
        } else {
            alert('Please fill out all the form');
        }
    }

    const handleFormChange = (e: any) => {
        setState({
            ...state,
            startSendingMessage: false
        })
        setContactState({
            ...contactState,
            [e.target.name]: e.target.value
        })
    }

    const parseHtml = (str: string) => {


        return (parse((str + ' ').replaceAll('&lt;', '<')
            .replaceAll('&gt;', '>')
            .replaceAll('&nbsp;', ' ')
            .replaceAll('&quot;', '"')

        ));
    }

    /**
     * show shop articles
     * 
     * 
     */
    const showShop = (e: any) => {
        //console.log('showing blogs');
        return <>{e.articles.map((response: any) => {

            return response.map((data: any) => {


                let myObj: any;
                myObj = data;
                return (<><article className="article-blog-page app-flex">
                    <div className="article-cover">
                        <img src={appHost + myObj.lastImage} style={{ maxWidth: "200px" }} alt="" />
                    </div>
                    <div className="article-core app-flex">
                        <h3><Link to={"/blog/" + myObj.slug}>{myObj.articleTitle}</Link></h3>
                        <div>
                            <Link className="read-bt" to={"/blog/" + myObj.slug}>{tools.translate('Lire plus')}</Link>
                        </div>
                    </div>
                </article></>)

            })

        })} </>

    }
    /**
     * show projects
     * 
     * 
     */
    const showProjects = (e: any) => {
        return <>{e.articles.map((response: any) => {

            return response.map((data: any) => {


                let myObj: any;
                myObj = data;
                return (<><article className="article-blog-page app-flex">
                    <div className="article-cover">
                        <img src={appHost + myObj.lastImage} style={{ maxWidth: "200px" }} alt="" />
                    </div>
                    <div className="article-core app-flex">
                        <h3><Link to={"/blog/" + myObj.slug}>{myObj.articleTitle}</Link></h3>
                        <div>
                            <Link className="read-bt" to={"/blog/" + myObj.slug}>{tools.translate('Lire plus')}</Link>
                        </div>
                    </div>
                </article></>)

            })

        })} </>

    }
    const [projects, setProjects]: [any, any] = useState({ list: [] });
    const getProjects = () => {
        axios.post(baseURL + 'allProjects')
            .then((res: any) => {
                setProjects({
                    ...projects,
                    list: res.data.allarticles
                });
            })
    }
    const runApp = () => {
        getRecentPosts();
        getProjects();

        //getRecentProducts();


        getRecentServices();

        getUserData();


    }

    const getUserData = () => {
        axios.post(baseURL + 'getuserdata/',
            {
                header: localStorage.getItem("loginToken")
            }).then((response: any) => {
                //console.log('user name', response.data.userName);
                setUserState({
                    ...userState,
                    userName: response.data.user.userName
                });
            })
    }
    const handleScroll = (e: any) => {
        let target = e.target;
        //console.log(target.scrollHeight);
        // console.log('scrolling');
    }
    const properties = {
        prevArrow: <button className="left-arrow-icon"><LeftIcon /> </button>,
        nextArrow: <button className="right-arrow-icon"><RightIcon /></button>
    }
    const [settingsState, setSettingsState]: [any, any] = useState({
        id: ''
    });

    const changeEachSettingsState = (id: string) => {
        setSettingsState((prev: any) => ({
            [id]: !prev[id]
        }))
    };


    React.useEffect(() => {
        runApp();

    }, []);
    return (<>

        <div >
            {/*
           <div className="banner-slide-wrap">
            <BannerSlide />
           </div>*/}
            <div id="home-top" className="home-simple-banner" style={{
                backgroundImage: 'url("' + TeamBannerImageMin + '")',
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
            }}>
                <div className="banner-overlay"></div>
                <div className="banner-main-content elem-row">
                    <div className="elem-col">
                        {/* <AnimateTextWidget customText="Hello world, welcome to my portfolio" /> */}
                        <h1 className="elem-animate-text">I'm Francky</h1>
                        <p>{tools.translate('I\'m a freelance software developer from Madagascar.')}</p>
                        <a href="#who-am-i" className="app-bt">{tools.translate('About me')}</a>
                        <a href="#contactme" className="app-bt">{tools.translate('Contact me')}</a>
                    </div>
                </div>
            </div>

            {/*<Header/>*/}

            <main className="home-app-core app-core">
                {/*banner here*/}
                <section id="who-am-i" className={(userInfo.currentPage === "who-am-i" ? "elem-slide-in " : "") + " who-am-i-section"}>

                    <h2 className="elem-section-title">{tools.translate('Who ')}<span className="elem-text-violet">{tools.translate('am I ?')}</span></h2>
                    <div className="elem-wrap-hide">
                        <div className="elem-row">

                            <div className="elem-col">
                                <div className="about-me-image-wrap"
                                    style={{
                                        backgroundImage: 'url("' + AboutMeRounded + '")',
                                        backgroundSize: "cover",
                                    }}>

                                </div>

                                <p>{tools.translate('Hi there, my name is Francky Rakoto. I\'m a freelance software developer from Antananarivo. I am specialized in creating cross-plateform desktop applications using wxWidgets and the C++ programming language.')}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="why-choose-me" className={(userInfo.currentPage === "chooseme" ? "elem-slide-in " : "") + " why-choose-me-section elem-section"}>
                    <h2 className="elem-section-title">{tools.translate('Why you need to')} <span className="elem-text-violet">{tools.translate('work with me?')}</span></h2>

                    <div className="elem-wrap-hide elem-row">

                        <div className="elem-col elem-col-animate">
                            <h3><span className="important-icon">01</span><span>{tools.translate('You need a website')}</span></h3>
                            <p>{tools.translate('You want to have your own website but don\'t know where to get started? You came in the right place, I will create for you your custom website')}</p>
                        </div>
                        <div className="elem-col elem-col-animate">
                            <h3><span className="important-icon">02</span><span>{tools.translate('Need a website refonte')}</span></h3>
                            <p>{tools.translate('You already have a website but you want to git a new look and feel? Oh yeah, I can support you with that, we can even go further and pick up new technologies like reactjs to get start with the project')}</p>

                        </div>
                        <div className="elem-col elem-col-animate">
                            <h3><span className="important-icon">03</span><span>{tools.translate('You need a web app for your business')}</span></h3>
                            <p>{tools.translate('You have a business that needs a web app to manage it online or locally, I can create your very own web app using modern technologies, contact me and you will get your dream web app')}</p>

                        </div>
                    </div>
                </section>
                {/*<section className="elem-max-sec who-am-i">
                    <h2>{tools.translate('Who am I')}?</h2>
                    <p>{tools.translate(`Je suis Francky Rakoto.
                        Bienvenue sur mon site web! Je suis un développeur de logiciels passionné par la création d'applications innovantes et conviviales.
                         Avec plusieurs années d'expérience dans l'industrie, j'ai eu l'opportunité de travailler sur un large éventail de projets, 
                         allant des applications web à petite échelle aux logiciels d'entreprise à grande échelle`)}....</p>
                    <div>
                        <Link className="homepage-bt" to="/about">{tools.translate('Lire plus')}</Link>
                    </div>
                </section>*/}

                {/* {typeof productState.recentProducts !== 'undefined' ? (
                    <section className="shop-section">
                        <div className="shop-section-container default-container">
                            <h2>{tools.translate('Boutique')}</h2>

                            {productState.recentProducts.length <= 0 ? (<p className="loading-simple">Chargement..</p>) : (<></>)}
                            <div className="shop-article-wrap">
                                {
                                    productState.recentProducts.map(data => {

                                        let myObj: any;
                                        myObj = data;
                                        // console.log('my data ', data);
                                        //console.log(myObj.articleTitle)
                                        return (<><article className="app-flex"><div className="article-cover" style={{ backgroundImage: "url(" + appHost + myObj.lastImage + ")" }}></div><div className="article-core app-flex"><h3><Link to={"blog/" + myObj.slug}>{myObj.articleTitle}</Link></h3>

                                            <div className="app-flex">
                                                <Link to="/login"><FaDownload />Télécharger</Link>
                                            </div>
                                        </div>
                                        </article></>)
                                    })
                                }

                            </div>
                            {productState.recentProducts.length ? (<><nav className="see-books">
                                <a className="see-more-bt app-bt" href="#">Voir plus</a>
                            </nav></>) : (<></>)}
                        </div>
                    </section>) : (<></>)} */}

                <section id="techstack" className={(userInfo.currentPage === "techstack" ? "elem-slide-in " : "") + " techstack-section elem-section"} >
                    <h2 className="elem-section-title"><span className="elem-text-violet">{tools.translate('Tech stack')}</span> {tools.translate('I work with most')}</h2>
                    <p className="elem-text-center text-center">{tools.translate('List of technologies I work with most')}</p>
                    <div className="elem-wrap-hide elem-row">
                        <div className="elem-col elem-text-center text-center">

                            <img src={`${ReactIcon}`} className="elem-zoom-out elem-logo-icon" />

                            <img src={`${CppIcon}`} className="elem-zoom-out elem-logo-icon" />

                            <img src={`${WpIcon}`} className="elem-zoom-out elem-logo-icon" />
                            <img src={`${PhpIcon}`} className="elem-zoom-out elem-logo-icon" />
                            <img src={`${JsIcon}`} className="elem-zoom-out elem-logo-icon" />
                        </div>
                    </div>


                </section>
                <section id="myservices" className={(userInfo.currentPage === "services" ? "elem-slide-in " : "") + " elem-section services-studio"} >
                    <h2 className="elem-section-title">{tools.translate('What I')} <span className="elem-text-gold">{tools.translate('do')}</span></h2>
                    <div className="elem-wrap-hide elem-row">
                        {serviceState.recentServices.length ? (<>
                            {
                                serviceState.recentServices.map(data => {

                                    let myObj: any;
                                    myObj = data;
                                    // console.log('my data ', data);
                                    //console.log(myObj.articleTitle)
                                    return (<div className="elem-col" id={"services" + myObj.id} key={"services" + myObj.id}>
                                        <div className="elem-col-container">
                                            <article>
                                                <div className="article-cover" style={{ backgroundImage: "url(" + appUpload + "datas/image/" + myObj.previewImageSrc + ")" }}>
                                                </div>
                                                <div className="article-core app-flex">
                                                    <h3>{myObj.articleTitle}</h3>
                                                    <div><>{parseHtml(myObj.description)}</></div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>)
                                })
                                //end service map loop
                            }</>) : (<><div className="loading-datas"><p>{tools.translate('Loading..')}</p></div></>)}

                    </div>



                </section>
                {/*<section id="app-main-blog" className="elem-max-sec enhenced-blog-section">
                    <div>
                        <div className="enhenced-blog-wrap">

                            <h2>{tools.translate('articles et boutique')}</h2>
                            <Pagination url={baseURL + 'allarticles/'} fallBack={showShop} />
                        </div>
                    </div>
                </section>*/}
                {/* <section className="blog-section">
        <div className="blog-section-container default-container">
            <h2>Articles recents</h2>
        <div className="new-blog-wrap">
            <article>
                <h3>Comment trouver du travail en 2022 le livre</h3>
                <p>Le livre comment trouver du travail en 2022 est pret..</p>
            </article>
            <article>
                <h3>Le livre guide utilisation de git en entreprise est apparu</h3>
                <p>Le livre comment trouver du travail en 2022 est pret..</p>
            </article>
            <article>
                <h3>Digital painting 1:</h3>
                <p>Voici une peinture digitale que j'ai fait</p>
            </article>
        </div>
        </div>
    </section>*/}
                {/*<section className="portfolio-section">
        <div className="portfolio-container default-container">
            <h2>Portfolio</h2>
        <div className="portfolio-wrap">
            <article>
                <h3>Cms avec php</h3>
            </article>
            <article>
                <h3>Site e-commerce avec php</h3>
            </article>
            <article>
                <h3>Site sns avec php</h3>
            </article>
        </div>
        </div>
    </section>*/}
                {/* {typeof serviceState.recentServices !== 'undefined' ? (
                    <section className="elem-max-sec services-section">
                        <div className="services-container default-container">
                            <h2>{tools.translate('My services')}</h2>
                            <div className=" ">

                                {serviceState.recentServices.length <= 0 ? (<p className="loading-simple"><PuffLoader />Chargement..</p>) : (<>

                                    <Slide slidesToShow={1} slidesToScroll={1}  {...properties}>
                                        {
                                            //start service loop
                                            serviceState.recentServices.map(data => {

                                                let myObj: any;
                                                myObj = data;
                                                // console.log('my data ', data);
                                                //console.log(myObj.articleTitle)
                                                return (<div className="each-slide-effect" id={"services" + myObj.id} key={"services" + myObj.id}>
                                                    <div style={{ 'background': `linear-gradient(45deg, black, transparent)` }}>
                                                        <article>
                                                            <div className="article-cover" style={{ backgroundImage: "url(" + appHost + myObj.lastImage + ")" }}>
                                                            </div>
                                                            <div className="article-core app-flex">
                                                                <h3>{myObj.articleTitle}</h3>
                                                                <div><>{parseHtml(myObj.description)}</></div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>)
                                            })
                                            //end service map loop
                                        }

                                    </Slide>


                                </>)}
                                {serviceState.recentServices.length >= 0 ? (<><nav className="see-books">

                                </nav></>) : (<></>)}
                            </div>

                        </div>
                    </section>
                ) : (<></>)}*/}

                {/*why choose francky rakoto*/}
                {/*<section className="reason-choose-service">
    <div className="reason-wrap">
        <h2>Pourquoi  choisir nos services?</h2>

        <div className="reason-container">
        <div className="reason-card"><h3>Coût développement</h3>
        <p>Le coût de développement des applications web sera toujours adapté à votre budget</p>
        </div>
        </div>
    </div>
  </section>*/}

                {/* <section className="use-cases">

                    <div className="elem-max-sec projects-container use-case-wrap">
                        <h2>{tools.translate('My Projects: ')}</h2>
                        <p>{tools.translate('Some projects on my portfolio or sources codes, templates, assets etc')}.</p>
                        <div className="projects-item-wrap">
                            <Pagination url={baseURL + 'allprojects/'} fallBack={showProjects} />
                        </div>
                    </div>
                </section> */}
                <section id="myprojects" className={(userInfo.currentPage === "projects" ? "elem-slide-in " : "") + " sample-project-section elem-section"} >
                    <h2 className="elem-section-title">{tools.translate('Sample of my')} <span className="elem-text-violet">{tools.translate('projects')}</span></h2>

                    <div className="elem-wrap-hide elem-row" style={{ flexWrap: "wrap" }}>
                        {projects.list.length ? (<>
                        </>) : (<>
                            {tools.translate('Loading..')}
                        </>)}
                        {
                            projects.list.map((data: any) => {

                                let myObj: any;
                                myObj = data;
                                // console.log('my data ', data);
                                //console.log(myObj.articleTitle)
                                return (<div className="elem-col" id={"portfolio" + myObj.id} key={"softwares" + myObj.id}>
                                    <div className="elem-col-container" >
                                        <article>
                                            <div className="article-cover" style={{ backgroundImage: "url(" + appUpload + "datas/image/" + myObj.previewImageSrc + ")" }}>
                                            </div>
                                            <div className="article-core app-flex">
                                                <h3>{myObj.articleTitle}</h3>
                                                <div><>{parseHtml(myObj.description)}</></div>
                                            </div>
                                        </article>
                                    </div>
                                </div>)
                            })
                            //end service map loop
                        }



                    </div>
                </section>
                {/**
    **contact form*/}
                {/* style={{ backgroundImage: 'url("' + ContactImage + '")', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundAttachment: "fixed" }} */}
                {/* style={{ background: "linear-gradient(rgba(255,198,0,0.9), rgba(200,200,0,0.6)" }} */}
                <section id="contactme" className="contact-section elem-section" >
                    <div className="contact-container default-container">
                        <div id="contact-form-wrap" className="contact-form-wrap" style={{ display: state.messageSent ? "none" : "block" }}>

                            <h2 id="contact-page" className="elem-section-title">{tools.translate('Contact me')}</h2>
                            <div className="elem-row-test">
                                <div className="elem-col">
                                    <p>{tools.translate('Contact me if you have a project you want to talk me with')}</p>
                                    <div className="contact-wrap">
                                        <form onSubmit={submitContactForm}>

                                            {/* <label ><span className="form-title">{tools.translate('Full name')}</span>
                                                <input type="text" name="userName" placeholder={tools.translate('Your name')} onChange={handleFormChange} /></label> */}

                                            <TextField id="standard-basic" label="Full Name" fullWidth variant="standard" name="userName" onChange={handleFormChange} />
                                            <br />
                                            {/* <div className="show-contact-input" onClick={() => changeEachSettingsState('contactParam2')} ><div className="add-bt">+</div>{tools.translate('Please enter your email')}</div> */}
                                            {/* style={{ display: settingsState['contactParam2'] ? "block" : "none" }} */}
                                            {/* <label ><span className="form-title">{tools.translate('Email')}</span>
                                                <input type="text" name="userEmail" placeholder={tools.translate('Your email')} onChange={handleFormChange} /></label> */}
                                            <TextField id="standard-basic" label="Email" fullWidth variant="standard" name="userEmail" onChange={handleFormChange} />
                                            <br />
                                            <br />

                                            <label><span className="form-title">{tools.translate('Tell me more about your project')}</span>
                                                <textarea defaultValue={tools.translate('Tell me more about your project')} name="userMessage" onChange={handleFormChange}>

                                                </textarea></label>
                                            <br />
                                            <label className="checkbox-label">
                                                <input type="checkbox" name="userMessageCompliance" onChange={handleFormChange} /><span>&nbsp;{tools.translate('Yes, those informations are true')}</span>
                                            </label>
                                            {state.startSendingMessage ? <>
                                                <LoadingButton
                                                    loading
                                                    loadingPosition="start"

                                                    variant="outlined"
                                                    type="submit"
                                                    className="app-bt"
                                                    style={{ paddingLeft: "34px" }}
                                                >
                                                    {tools.translate('Send message')}
                                                </LoadingButton></> : <>
                                                <Button variant="outlined" type="submit" className="app-bt">
                                                    {tools.translate('Send message')}
                                                </Button></>}
                                            {/* <button className="app-bt send-message-bt">{tools.translate('Send message')}{state.startSendingMessage ? (<><span>..</span></>) : (<></>)}</button> */}
                                        </form>

                                    </div>
                                </div>
                            </div>{/*end elem row*/}
                        </div>
                        {state.messageSent ? (<div className="message-sent"><p>{tools.translate('Thank  you. Your message was sent ')}</p><HiCheck className="message-sent-check" />
                        </div>) : (<div className="error-message-sent"></div>)}

                        {/*
			User name: {contactState.userName}<br/>
			email: {contactState.userEmail}<br/>
                        message: {contactState.userMessage}<br/>
                        compliance: {contactState.userMessageCompliance}<br/>*/}
                        {/* <br />
                        {contactState.userEmail}<br />
                        {contactState.userMessage} */}
                    </div>
                </section>
            </main>

            <Footer />
        </div>

    </>);
}

export default Home;
