import React from "react";
import { Link } from "react-router-dom";
import { HiMenu } from "react-icons/hi";
import FranckLogo from "../../image/footer-logo-400x400.png";
import SearchForm from "./SearchForm";
import * as tools from "../utils/tools";
export const AppLogo = (prop: any) => {

    const toggleLeftBar = () => {
        prop.showLeftColProp();
    }
    return <>
        <div className="app-logo"  >
            <div className="elem-flex-center flex-container app-flex" style={{ width: "100%", alignItems: "center", justifyContent:'space-between' }}>
                {/*<HiMenu className="main-menu-bt" onClick={toggleLeftBar} />*/}
                <Link className="appName" to="/">

                    {/* <img src={FranckLogo} style={{
                        width: "32px", height: "32px",
                        borderRadius: "8px", background: "#000"
                    }} /> */}
                    <span className="author-name">Francky Rakoto</span></Link>
 
            </div>
        </div>
    </>
}

export default AppLogo;
