import React, { useState, useContext } from "react";
import { FaPhone, FaPlay, FaUser, FaLinkedin, FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaCheck } from "react-icons/fa";
import { HiMenu, HiPhone, HiMail, HiCheck, HiSearch, HiUser, HiLogout, HiPlus } from "react-icons/hi";
import { AiOutlinePoweroff as LogoutIcon, AiOutlineCloseCircle as CloseIcon } from "react-icons/ai";
import { CiSettings } from "react-icons/ci";
import { AiOutlineInfoCircle as InfoIcon, AiFillHome as HomeIcon } from "react-icons/ai";
import { HiAcademicCap as PortfolioIcon } from "react-icons/hi2";
import { BiUser } from "react-icons/bi";
import {IoMdArrowDropdown as DropDownIcon} from "react-icons/io";
import { Link, useParams, useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";
import UserContext from "../context/UserContext";
import * as tools from "../utils/tools";
import axios from "axios";
import Submenu from "./Submenu";
import SearchForm from "./SearchForm";
import AppLogo from "./AppLogo";
import MobileLeftMenu from "./MobileLeftMenu";
import Topbar from "./Topbar";
import ProfileTopMenu from "../profile/ProfileTopMenu";
import SimpleSearch from "./SimpleSearch";
import ScrollMenu from "../header/ScrollMenu";

const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';
const appUpload: string = process.env.REACT_APP_APP_UPLOAD + '';

export const HeaderLogin = (prop: any) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        showLeftBar: false
    })
    const [searchState, setSearchState] = useState({
        searchQuery: '',
        searchCategory: 'people'
    })
    const appContext = useContext(AppContext);
    const mainUserData = useContext(UserContext);

    const [userState, setUserState] = useState({
        userName: '',
        userType: '',
		profile: '',
		userNumber: ''

    });
    const [profileState, setProfileState] = useState({
        showMenu: false
    })


    const toggleLeftBar = () => {
        setState({
            ...state,
            showLeftBar: !state.showLeftBar
        })
    }

    const showProfileMenu = (e: any) => {
        //e.target.preventDefault();
        setProfileState({
            ...profileState,
            showMenu: !profileState.showMenu
        });
    }
    const handleSearchChange = (e: any) => {
        setSearchState({
            ...searchState,
            [e.target.name]: (e.target.value)
        })
    }
    const submitSearchForm = (e: any) => {
        e.preventDefault();

        navigate('/search/query=' + searchState.searchQuery + '&category=blog');
    }

    const getUserData = () => {
        axios.post(baseURL + 'getuserdata/',
            {
                header: localStorage.getItem("loginToken")
            }).then((response: any) => {
                console.log('user name', response.data.userName);
                setUserState({
                    ...userState,
                    userName: response.data.user.userName,
					profile: response.data.user.profile,
					userNumber: response.data.user.userNumber
                });
            })
    }

    /*const logout = () => {
        prop.loginProp(false);

        localStorage.removeItem('loginToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        localStorage.removeItem('userDateCreation');
        navigate('/login/');
    }*/
    //using context
    const {logout, isLogin, setIsLogin,userInfo, setUserInfo}:any=useContext(AppContext);

    const [showSearchInput, setShowSearchInput]:[any,any]=useState(false);
    
    const [userSearch, setUserSearch]=useState({
        query: '',
        category: 'people'
    });
    
    const getSearchResult=(e:any)=>{
        setUserSearch({
            ...userSearch,
            [e.target.name]: (e.target.value)
        })
    }

    const [simpleSearchResult, setSimpleSearchResult]:[any,any]=useState({
        all: ''
    })
    const simpleSearchSubmit=(e:any)=>{
        e.preventDefault();

        axios.post(baseURL+'search/', {
            header: localStorage.getItem('loginToken'), 
            query: userSearch.query, 
            category: userSearch.category
        }).then((res:any)=>{
            if(res.data.status==='success'){
                setSimpleSearchResult({...simpleSearchResult, 
                all: res.data.search})
            } 
        })
    }

    const [settingsState, setSettingsState]:[any,any]=useState({
          id: ''
      });
 
      const changeEachSettingsState=(id:string)=>{
          setSettingsState((prev: any) => ({
              [id]: !prev[id]
          }))
      };

    React.useEffect(() => {

        // window.addEventListener('scroll',function(e:any){
        // 	console.log('scrolling');
        // 	/*console.log('offset heigt',document.body.offsetHeight);
        // 	console.log('offset top', document.body.offsetTop);
        // 	console.log('client top', document.body.clientTop);
        // 	console.log('client height', document.body.clientHeight);*/
        //     console.log('document.documentElement', document.documentElement.offsetHeight);

        //     console.log('document.documentElement', document.documentElement.clientHeight);


        //     console.log('document.documentElement', document.documentElement.clientTop);

        //     console.log('document.documentElement', document.documentElement.scrollTop);
        //    // console.log('scroll top: ',e.target.scrollTop);
        //    //console.log('scroll height: ',e.target.scrollHeight);
        // });
       // console.log('login prop ', prop.isLoggedInProp);
        // getUserData();
    }, [])
    return <>

        {/*<Topbar/>*/}
        {/*<MobileLeftMenu showLeftBar={state.showLeftBar} showLeftColProp={toggleLeftBar} />*/}
<SimpleSearch/>
        <header className={prop.fixedProp?"app-header-fixed app-header":"app-header"} style={{position: prop.fixedProp?"fixed":"relative"}}>
		<div className="section-default-wrap">
            <div className="app-logo-wrap">
                <AppLogo showLeftColProp={toggleLeftBar} />
                <div className="search-header">
                     {/*<div className="elem-inline-flex">
                        <Link to="/classrooms" style={{ color: "#000", fontSize: "14px", border: "1px solid #333", padding: "5px 10px" }}>{tools.translate('Hianatra')}</Link>
                    </div>*/}
                    <SimpleSearch/>
{/*
                    <HiSearch className="search-icon" onClick={()=>{setShowSearchInput(!showSearchInput)}}/>
*/}                </div>
            </div>
            <div className="top-right-menu">
                <nav className="default-menu">
                <ul>
                <li><a href="#home-top">{tools.translate('Home')}</a></li>
                <li><a href="#who-am-i" className={userInfo.currentPage==='who-am-i'?'active-page':''}>{tools.translate('Who am I')}</a></li>
                <li><a href="#why-choose-me" className={userInfo.currentPage==='chooseme'?'active-page':''}>{tools.translate('Why me')}</a></li>
                <li><a href="#techstack" className={userInfo.currentPage==='techstack'?'active-page':''}>{tools.translate('Tech')}</a></li>
                <li><a href="#myservices" className={userInfo.currentPage==='services'?'active-page':''}>{tools.translate('Services')}</a></li>
                <li><a href="#myprojects" className={userInfo.currentPage==='projects'?'active-page':''}>{tools.translate('Projects')}</a></li>
                <li><a href="#contactme" className={userInfo.currentPage==='contactme'?'active-page':''}>{tools.translate('Contact')}</a></li>
                </ul>
                </nav>   
               
               <nav className="mobile-sticky-menu-scroll">
<ScrollMenu/>
               </nav> 
               <div className="mobile-default-menu-wrap">
               <HiMenu style={{cursor: "pointer"}} onClick={()=>changeEachSettingsState('mobileMenu')}/>
                <nav className="mobile-default-menu" style={{display: settingsState['mobileMenu']?"block":"none"}}>  
                <ScrollMenu/>
                </nav>
               </div>
 
                <span onClick={showProfileMenu} className="user-name-bt" style={{display:"flex",  alignItems:"center",justifyContent:"space-between"}}>
                {/* {userInfo.profilePicture!==null?(<>
                    <div style={{ borderRadius:"100%",marginRight:"10px",width:"32px",height:"32px",backgroundSize:"cover",backgroundRepeat:"no-repeat",
                    backgroundImage: "url('"+appUpload + '/users/' + userInfo.id + '/image/' + userInfo.profilePicture+"')"}}> 
                    </div>
                </>):(<> 
                     <HiUser className="client-connect-bt" />
                    </>)} */}
               
                {/* {userInfo.name.split(' ').length>=1? userInfo.name.split(' ')[0]:userInfo.name}  */}
                {/*<Link to="/login" className="app-bt" >{tools.translate('Connect')}</Link>*/}
                </span>
				{/* <ProfileTopMenu showMenuProp={showProfileMenu} userDataProp={userState} logout={logout} showMenu={profileState.showMenu}/> */}
            {/*    <Link to="/contact" className="got-a-project-bt-top">Nouveau projet <span className="addIcon"><HiPlus /></span></Link>

*/}

            </div>
			</div>
        </header>
        
       {/* <nav className="app-main-menu">
		    <div className="section-default-wrap">
            <Submenu />
			</div>
        </nav>*/}
    </>
}


export default HeaderLogin;
    
