import React, { useState, useContext } from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import axios from "axios";
import "../template/portfoliofrancky/css/app.css";
import { FaPhone, FaPlay, FaUser, FaLinkedin, FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaCheck } from "react-icons/fa";
import { HiMenu, HiPhone, HiMail, HiCheck } from "react-icons/hi";
import "../../fonts/Lato/Lato-Bold.ttf";
import "../../fonts/Poppins/Poppins-Bold.ttf";
import "../../fonts/Poppins/Poppins-Regular.ttf";
import "../../fonts/OpenSans-Regular.ttf";
import Home from "./home/Home";
import About from "./about/About";
import Contact from "./contact/Contact";
import Blog from "./blog/Blog";
import ReadBlog from "./blog/ReadBlog";
import Login from "./login/Login";
import Subscribe from "./login/Subscribe";
import Search from "./search/Search";
import SearchPeople from "./search/SearchPeople";
import ProfileHome from "./profile/ProfileHome";
import ProfileUpdate from "./profile/ProfileUpdate";
import AppContext, { AppContextProvider } from "./context/AppContext";

import Header from "./header/Header";
import HeaderLogin from "./header/HeaderLogin";
import ViewProfile from "./profile/ViewProfile";
import JokePage from "./jokes/JokePage";
import DedicatedMessages from "./dedicaces/DedicatedMessages";
import Impressum from "./portfolio/Impressum";
import * as tools from "./utils/tools";
import UserMap from "./profile/usermap/UserMap";
import ProfileTopMenu from "./profile/ProfileTopMenu";
import { UserProvider } from "./context/UserContext";
import UserContext from "./context/UserContext";
import UserClassrooms from "./profile/UserClassrooms";
import ViewClassroom from "./profile/ViewClassroom";
import ViewClassroomSettings from "./profile/ViewClassroomSettings";
import AddNote from "./profile/AddNote";
import ProfileFiles from "./profile/ProfileFiles";
import UserNotifications from "./profile/UserNotifications";

let baseURL: any;
baseURL = process.env.REACT_APP_API_URL;

const AppContainer = () => {
    const [state, setState] = useState({
        userLogin: false,
        showLeftBar: false,
        messageSent: false,

        recentPosts: []
    })

    const [contactState, setContactState] = useState({
        userName: '',
        userEmail: '',
        userMessage: '',
    })

    const [loginState, setLoginState] = useState({
        login: false
    });

    const [userState, setUserState] = useState({
        userEmail: '',
        userName: ''
    });

    const { isLogin, setIsLogin, checkToken, setCheckToken, checkingToken,errorLogin,userInfo,setUserInfo } = useContext(AppContext);

    /*const login = () => {
        setState({
            ...state,
            userLogin: !state.userLogin
        })
    }*/

    // const getRecentPosts=()=>{

    //     axios.get(baseURL+'articles/').then((response)=>{
    //         console.log(response.data.articles);
    //         setState({
    //             ...state,
    //             recentPosts: response.data.articles
    //         })
    //     })
    // }

    const iconStyle = { fill: '#4ad', width: '32px', height: '26px' }

    const toggleLeftBar = () => {
        setState({
            ...state,
            showLeftBar: !state.showLeftBar
        })
    }

    const submitContactForm = (e: any) => {

        e.preventDefault();
        let dataToSend = {
            message: contactState.userMessage,
            email: contactState.userEmail,
            name: contactState.userName
        };

        axios.post(baseURL + "contactus",
            dataToSend).then(response => {
                console.log(response.data.status);
                console.log(typeof response.data.status);
                if (response.data.status === 'success') {
                    console.log('message sent');
                    setState({
                        ...state,
                        messageSent: true
                    });
                } else {
                    setState({
                        ...state,
                        messageSent: false
                    });
                }
            })
    }

    const checkLoginState = (isLoggedIn: boolean) => {
        //appContext=useContext(AppContext);
        setLoginState({
            ...loginState,
            login: isLoggedIn
        });
    }
    const handleFormChange = (e: any) => {
        setContactState({
            ...contactState,
            [e.target.name]: e.target.value
        })
    }

    /*
    const checkingToken = () => {
        axios.post(baseURL + 'checktoken', {
            'loginToken': localStorage.getItem('loginToken')

        }).then(res => {
            if (res.data.status === 'success') {
                setCheckToken(true);
            }
        })
    }*/
    const checkUserOnline = () => {
        //appContext=useContext(AppContext);
        //console.log(appContext.isOnline);
        //checkingToken();
        if (localStorage.getItem("loginToken") !== null && checkToken) {
            checkLoginState(true);
            setIsLogin(true);
        
        setUserInfo((prevState:any) => ({
                ...prevState,
                name:  localStorage.getItem('userName'),
                biography: localStorage.getItem('userBiography'),
                money:  parseInt(localStorage.getItem('userMoney')+'',10),
                profilePicture: localStorage.getItem('profilePicture'),
                id: localStorage.getItem("userId")
              }));
            return true;
        }
        return false;
    }



    const getUserData = () => {
        axios.post(baseURL + 'getuserdata/',
            {
                header: localStorage.getItem("loginToken")
            }).then((response: any) => {
                //       console.log('user name', response.data.userName);
                setUserState({
                    ...userState,
                    userName: response.data.user.userName
                });
            })
    }

    //change profile state 
    const [changeProfile, setChangeProfile] = useState(false);


    const [makeHeaderFixed, setMakeHeaderFixed] = useState(false);

    const [showSubmenu, setShowSubmenu] = useState(true);

    const hideSubmenu = (pshow: boolean) => {
        setShowSubmenu(pshow);
    }
    const scrollEventHeader = () => {

        window.addEventListener('scroll', function (e: any) {

            //whole page height
            // console.log('offset height', document.documentElement.offsetHeight);

            //current page percent scrolled
            // console.log('client height', document.documentElement.clientHeight);


            // console.log('client top', document.documentElement.clientTop);

            // console.log('scroll top', document.documentElement.scrollTop);
            let userScroll = document.documentElement.scrollTop;
            let chooseMe:any=document.querySelector('#why-choose-me');
            let myTech:any=document.querySelector('#techstack');
            let portfolioServices:any=document.querySelector('#myservices');            let portfolioProjects:any=document.querySelector('#myprojects');            let portfolioContact:any=document.querySelector('#contactme');
            let whoAmISection:any=document.querySelector('#who-am-i');
         
            if (userScroll >=10) {
                setMakeHeaderFixed(true);
            } else if (userScroll <= 0) {
                setMakeHeaderFixed(false);
            }
          if(userScroll <whoAmISection.offsetTop){
              localStorage.setItem('currentPage', 'top');
              setUserInfo((prevState:any)=>({
                  ...prevState, 
                  currentPage:'top'}));

          }else if(userScroll>=whoAmISection.offsetTop && userScroll < chooseMe.offsetTop){
           localStorage.setItem('currentPage', 'who-am-i');
           setUserInfo((prevState:any)=>({
                ...prevState,
                currentPage: 'who-am-i'
            }))
 }else if(userScroll>=chooseMe.offsetTop && userScroll < myTech.offsetTop-5){
                localStorage.setItem('currentPage', 'chooseme');
                console.log('choose me activated');
                 setUserInfo((prevState:any)=>({
                          ...prevState,
                         currentPage : 'chooseme'
                  }))

            }else if(userScroll>=myTech.offsetTop && userScroll <portfolioServices.offsetTop-5){
                localStorage.setItem('currentPage', 'techstack');
                console.log('tech activated');
                setUserInfo((prevState:any)=>({
                           ...prevState,
                          currentPage : 'techstack'
                   }))

	    }else if(userScroll>=portfolioServices.offsetTop && userScroll < portfolioProjects.offsetTop-5){
                localStorage.setItem('currentPage', 'services');
                console.log('services activated');
                 setUserInfo((prevState:any)=>({
                            ...prevState,
                           currentPage : 'services'
                    }))

            }else if(userScroll>=portfolioProjects.offsetTop && userScroll < portfolioContact.offsetTop-5 ){
		localStorage.setItem('currentPage', 'projects');
                console.log('projects activated');
                 setUserInfo((prevState:any)=>({
                            ...prevState,
                           currentPage : 'projects'
                    }))

	    }else if(userScroll>=portfolioContact.offsetTop){
                localStorage.setItem('currentPage', 'contactme');
                console.log('contact activated');
                setUserInfo((prevState:any)=>({
                             ...prevState,
                            currentPage : 'contactme'
                     }))

            }
            console.log('user scroll'+userScroll);
            console.log('page: '+localStorage.getItem('currentPage'));
            /*if(chooseMe)
            console.log('choose me top '+chooseMe.offsetTop);
            if(myTech)
            console.log('tech top '+myTech.offsetTop);
            if(portfolioServices)
            console.log('services top'+portfolioServices.offsetTop);
            */

        });

        //body event
        // document.body.addEventListener('click', (e: any) => {
        //     if (e.target !== null) {
        //         const elemClasses = e.target.className;
        //         // console.log(e.target);
        //         console.log(e.target.className)
        //         if (elemClasses === 'show-more-bt' || elemClasses === 'show-more-bt-icon') {
        //             setShowSubmenu(true);
        //         } else {
        //             setShowSubmenu(false);
        //         }
        //     }
        // })
    }
    React.useEffect(() => {
        checkUserOnline();
        checkingToken();
        tools.getLastProfile();
        scrollEventHeader();
    }, [isLogin, checkToken])

    return (<>

        {/*user provider */}
            

                <Header fixedProp={makeHeaderFixed} submenuProp={showSubmenu}   hideSubmenuProp={hideSubmenu} />
            
        <Routes>
         
           <Route path="*" element={<Home />} />
            


        </Routes>
    </>);
}

export default AppContainer;
