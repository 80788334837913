import React, {useState, useContext} from "react";
import {Link} from "react-router-dom";
import axios from "axios";

let baseURL: any;
let appHost: any;
baseURL = process.env.REACT_APP_API_URL;
appHost = process.env.REACT_APP_APP_HOST;


const NewsLetter=()=>{

const [emailState, setEmailState]:[any,any]=useState({
email: ''});
const [emailSending, setEmailSending]:[any,any]=useState(false);
const [emailSuccess, setEmailSuccess]:[any,any]=useState(false);

const handleChange=(e:any)=>{
    setEmailState({...emailState,
[e.target.name]:e.target.value});
}
const saveUserEmail=(e:any)=>{
    e.preventDefault();

    if(emailState.email.length>0 && /(.+)@(.+){2,}\.(.+){2,}/.test(emailState.email)){
    setEmailSending(true);
    axios.post(baseURL+'saveEmail/', { 
        userEmail: emailState.email
    }).then((res:any)=>{
        if(res.data.status==='success'){
            setEmailSuccess(true);
            setEmailSending(false);
        }        
    });
    }else{
        alert('Please enter a valid email');
    }
}

return <>
<div className="email-saved" style={{display:emailSuccess?"block":"none"}}>
Your email was saved, thank you!
</div>
<form className="newsletter-form" onSubmit={saveUserEmail} style={{display: emailSuccess?"none":"block"}}>
<input type="text" name="email" onChange={handleChange}/>
<button type="submit" className="app-bt" >Subscribe<span style={{display:emailSending?"inline-flex":"none"}}>..</span></button>
</form>
</>

}

export default NewsLetter;
