import React, { useState, useContext } from "react";
import { FaPhone, FaPlay, FaUser, FaLinkedin, FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaCheck } from "react-icons/fa";
import { HiMenu, HiPhone, HiMail, HiCheck, HiSearch, HiUser, HiLogout, HiPlus } from "react-icons/hi";
import { AiOutlinePoweroff as LogoutIcon, AiOutlineCloseCircle as CloseIcon } from "react-icons/ai";
import { CiSettings } from "react-icons/ci";
import { AiOutlineInfoCircle as InfoIcon, AiFillHome as HomeIcon } from "react-icons/ai";
import { HiAcademicCap as PortfolioIcon } from "react-icons/hi2";
import { BiUser } from "react-icons/bi";
import {IoMdArrowDropdown as DropDownIcon} from "react-icons/io";
import { Link, useParams, useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";
import UserContext from "../context/UserContext";
import * as tools from "../utils/tools";
import axios from "axios";
import Submenu from "./Submenu";
import SearchForm from "./SearchForm";
import AppLogo from "./AppLogo";
import MobileLeftMenu from "./MobileLeftMenu";
import Topbar from "./Topbar";
import ProfileTopMenu from "../profile/ProfileTopMenu";
const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';
const appUpload: string = process.env.REACT_APP_APP_UPLOAD + '';

export const SimpleSearch = (prop: any) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        showLeftBar: false
    })
    const [searchState, setSearchState] = useState({
        searchQuery: '',
        searchCategory: 'people'
    })
    const appContext = useContext(AppContext);
    const mainUserData = useContext(UserContext);

    const [userState, setUserState] = useState({
        userName: '',
        userType: '',
		profile: '',
		userNumber: ''

    });
    const [profileState, setProfileState] = useState({
        showMenu: false
    })


    const toggleLeftBar = () => {
        setState({
            ...state,
            showLeftBar: !state.showLeftBar
        })
    }

    const showProfileMenu = (e: any) => {
        //e.target.preventDefault();
        setProfileState({
            ...profileState,
            showMenu: !profileState.showMenu
        });
    }
    const handleSearchChange = (e: any) => {
        setSearchState({
            ...searchState,
            [e.target.name]: (e.target.value)
        })
    }
    const submitSearchForm = (e: any) => {
        e.preventDefault();

        navigate('/search/query=' + searchState.searchQuery + '&category=blog');
    }

    const getUserData = () => {
        axios.post(baseURL + 'getuserdata/',
            {
                header: localStorage.getItem("loginToken")
            }).then((response: any) => {
                console.log('user name', response.data.userName);
                setUserState({
                    ...userState,
                    userName: response.data.user.userName,
					profile: response.data.user.profile,
					userNumber: response.data.user.userNumber
                });
            })
    }

    /*const logout = () => {
        prop.loginProp(false);

        localStorage.removeItem('loginToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
        localStorage.removeItem('userDateCreation');
        navigate('/login/');
    }*/
    //using context
    const {logout, isLogin, setIsLogin,userInfo, setUserInfo}:any=useContext(AppContext);

    const [showSearchInput, setShowSearchInput]:[any,any]=useState(false);
    
    const [userSearch, setUserSearch]=useState({
        query: '',
        category: 'people'
    });
    
    const getSearchResult=(e:any)=>{
        setUserSearch({
            ...userSearch,
            [e.target.name]: (e.target.value)
        })
    }

    const [simpleSearchResult, setSimpleSearchResult]:[any,any]=useState({
        all: ''
    })
    const simpleSearchSubmit=(e:any)=>{
        e.preventDefault();

        axios.post(baseURL+'search/', {
            header: localStorage.getItem('loginToken'), 
            query: userSearch.query, 
            category: userSearch.category
        }).then((res:any)=>{
            if(res.data.status==='success'){
                setSimpleSearchResult({...simpleSearchResult, 
                all: res.data.search})
            } 
        })
    }
    React.useEffect(() => {

        // window.addEventListener('scroll',function(e:any){
        // 	console.log('scrolling');
        // 	/*console.log('offset heigt',document.body.offsetHeight);
        // 	console.log('offset top', document.body.offsetTop);
        // 	console.log('client top', document.body.clientTop);
        // 	console.log('client height', document.body.clientHeight);*/
        //     console.log('document.documentElement', document.documentElement.offsetHeight);

        //     console.log('document.documentElement', document.documentElement.clientHeight);


        //     console.log('document.documentElement', document.documentElement.clientTop);

        //     console.log('document.documentElement', document.documentElement.scrollTop);
        //    // console.log('scroll top: ',e.target.scrollTop);
        //    //console.log('scroll height: ',e.target.scrollHeight);
        // });
       // console.log('login prop ', prop.isLoggedInProp);
        // getUserData();
    }, [])
    return <>

      

        <div className="search-input-header" style={{display:showSearchInput?"flex":"none"}}>
        <form onSubmit={simpleSearchSubmit}> 
        <HiSearch className="search-input-icon"/>
        <input type="text" name="query" onChange={getSearchResult} placeholder={tools.translate("Fikarohana")}/>
       {/* <select name="category" className="search-input-category" onChange={getSearchResult} defaultValue="people">
        <option   value="people">{tools.translate('olona')}</option>
        <option value="class">{tools.translate('Kilasy')}</option> 
        <option value="shop">{tools.translate('Hividy')}</option>
        <option value="blog">{tools.translate('Blog')}</option>
        </select>*/}
        </form>
        <button className="close-search-input" onClick={()=>{setShowSearchInput(!showSearchInput)}}>x</button>
        <div className="search-header-result"  >
           <div className="search-tabs">
           <ul><li><a href="#">{tools.translate('Students')}</a></li>
	   <li><a href="#">{tools.translate('blog')}</a></li>
           <li><a href="#">{tools.translate('Shop')}</a></li>
           </ul>
           </div> 
            <h3>{tools.translate('Search something')}</h3>
            <p>{tools.translate('Searching') +' '+userSearch.category+' : '+userSearch.query}</p>
            {simpleSearchResult.all?(<>{tools.translate('Found: ')} {simpleSearchResult.all.length}</>):(<>{tools.translate('Mikaroka..')}</>)}
            {simpleSearchResult.all?(<>
                {simpleSearchResult.all.map((res:any)=>{
                    return <div>
                    {res.id===userInfo.userId?(<>
                        {/*current user*/}
                        {res.userName?(<><Link to={"/profile/"}>{res.userName}</Link></>):(<>anonymous</>)}
                    </>):(<>
                        {/*other user*/}
                        {res.userName?(<><Link to={"/viewprofile/"+res.id}>{res.userName}</Link></>):(<>anonymous</>)}
                    </>)}
                    
                    </div>
                })}
            </>):(<></>)}
        </div>
        </div>
</>               
}


export default SimpleSearch;
    
