import React, {useState, createContext} from "react";

// import * as appTools from "../utils/tools.js";

// const UserContext=React.createContext({user_name: "Rick Sanchez"});
const UserContext=React.createContext({});

export function UserProvider({children}:any){
    const [items, setItems]=useState([]);


    return (

    <UserContext.Provider value={{item: 1}}>{children}</UserContext.Provider>);

}



export default UserContext;
