import React, {useState,useContext} from "react";
import {Link} from "react-router-dom";
import AppContext from "../context/AppContext";
import * as tools from "../utils/tools";

const ScrollMenu=()=>{


const {userInfo}=useContext(AppContext);

return <>


                    <ul>
                <li><a href="#who-am-i" className={userInfo.currentPage==='who-am-i'?'active-page':''}><span className="hands">👉️</span> {tools.translate('Who am I?')}</a></li>
 
                    <li><a href="#why-choose-me" className={userInfo.currentPage==='chooseme'?'active-page':''}><span className="hands">👉️</span>{tools.translate('Why me?')}</a></li>
                    <li><a href="#techstack" className={userInfo.currentPage==='techstack'?'active-page':''}><span className="hands">👉️</span>{tools.translate('Tech')}</a></li>
                    <li><a href="#myservices" className={userInfo.currentPage==='services'?'active-page':''}><span className="hands">👉️</span>{tools.translate('Services')}</a></li>
                    <li><a href="#myprojects" className={userInfo.currentPage==='projects'?'active-page':''}><span className="hands">👉️</span>{tools.translate('Projects')}</a></li>
                    <li><a href="#contactme" className={userInfo.currentPage==='contactme'?'active-page':''}><span className="hands">👉️</span>{tools.translate('Contact')}</a></li>
                    </ul>


</>
}

export default ScrollMenu;
